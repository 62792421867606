<template>
  <v-row>
    <v-col class="col-sm-3 d-none d-sm-flex"></v-col>
    <v-col align="center" class="col-12 col-sm-6">
      <v-img max-height="574" max-width="574" src="@/assets/logo-dark.png"></v-img>
      <p v-if="!userData || !userData.isAuth" class="subheading font-weight-regular">
        <v-btn to="/login" color="primaryButton" class="white--text">Log In</v-btn>
      </p>
    </v-col>
    <v-col class="col-sm-3 d-none d-sm-flex"></v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Home',

    computed: {
      ...mapGetters([
        'userData'
      ])
    }
  }
</script>